import { gettext } from 'django'
import React from 'react'
import PropTypes from 'prop-types'
import ReactTable from 'react-table'
import { workspace } from '../clproxy'
import moment from 'moment'

// Register Translations here
// ----------------------------------------------------------------------
const transTitle = gettext('Your Transaction Dashboard')
const transNewProject = gettext('New Transaction')
const transNewProjectDesc = gettext('Create new Transaction Workspace')
const transTransaction = gettext('Transaction')
const transCompany = gettext('Company')
const transLenders = gettext('Lenders')
const transHelpers = gettext('Helpers')
const transToolsButton = gettext('Tools')
const transDeleteButton = gettext('Delete workspace')
const transDisabledDeleteButton = gettext('Info about why the workspace can\'t be deleted')
const transDeleteConfirmationMessage = gettext('Delete workspace confirmation message')
const transSwitchToHidden = gettext('Show hidden projects')
const transSwitchToVisible = gettext('Show visible projects')
const transShowButton = gettext('Show project')
const transHideButton = gettext('Hide project')
const transLastUpdated = gettext('Last update')
const transLastAccess = gettext('Last access')
const transConfirm = gettext('Confirm wks delete')
// ----------------------------------------------------------------------
class ProjectsBoard extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      visible: true,
      workspaces: JSON.parse(this.props.workspaces)
    }
  }

  componentDidMount () {
    window.$('[data-toggle="tooltip"]').tooltip()
  }

  componentDidUpdate () {
    window.$('[data-toggle="tooltip"]').tooltip()
  }

  _deleteWorkspace (slug) {
    if (window.confirm(transDeleteConfirmationMessage)) {
      window.location = `/workspace/${slug}/delete/`
    }
  }

  _switchVisibility () {
    this.setState({ visible: !this.state.visible })
  }

  render () {
    let buttons = (wk) => <span>
      <a href={`/workspace/${wk.slug}/`}
        title={transToolsButton} data-placement='top' data-toggle='tooltip'>
        <svg className='icon icon--primary'>
          <use xlinkHref='#icon-edit' />
        </svg>
      </a> &nbsp;
      {wk.erasable ? <a href='#'
        onClick={this._deleteWorkspace.bind(this, wk.slug)}
        title={transDeleteButton} data-placement='top' data-toggle='tooltip'>
        <svg className='icon icon--red'>
          <use xlinkHref='#icon-delete' />
        </svg>
      </a> : <a href='#' disabled
        title={transDisabledDeleteButton} data-placement='top' data-toggle='tooltip'>
        <svg className='icon icon--light'>
          <use xlinkHref='#icon-delete' />
        </svg>
      </a>}
    </span>
    let tableColumns = [
      {
        Header: '',
        width: 40,
        accessor: 'wk',
        Cell: (row) => (
        <a href={`/workspace/${row.value.slug}/toogle_visibility/`}
          title={this.state.visible ? transHideButton : transShowButton} data-placement='top' data-toggle='tooltip'>
          <svg className='icon icon--primary'>
            <use xlinkHref='#icon-eye' />
          </svg>
        </a>)
      },
      {
        Header: transTransaction,
        accessor: 'wk',
        Cell: row => <a href={`/workspace/${row.value.slug}/forms/`}>{row.value.name}</a>
      },
      { Header: transCompany, accessor: 'entity' },
      {
        Header: transLastAccess,
        accessor: 'last_access',
        Cell: row => moment(row.value).format(moment.defaultFormat + ', HH:mm')
      },
      {
        Header: transLastUpdated,
        accessor: 'last_update',
        Cell: row => row.value !== 'None' && moment(row.value).format(moment.defaultFormat + ', HH:mm')
      },
      {
        Header: transHelpers,
        accessor: 'helpers',
        Cell: row => <ul>{row.value?.split(',').map(name => <li key={name}>{name}</li>)}</ul>
      },
      {
        Header: transLenders,
        accessor: 'lenders',
        Cell: row => <ul>{row.value?.split(',').map(name => <li key={name}>{name}</li>)}</ul>
      },
      {
        Header: '',
        width: 65,
        Cell: row => row.original.can_user_edit_wk ? buttons(row.original.wk) : null
      }
    ]

    return (
      <div className='col-md-12'>
        <div className='page-title row'>
          <div className='col-md-6'>
            <h2>{transTitle}</h2>
          </div>
          <div className='col-md-6 align-right | mt-micro mt-medium'>
            <a href='#' onClick={this._switchVisibility.bind(this)} data-testid='switchBtn'>
              <button className='btn'>
                {this.state.visible ? transSwitchToHidden : transSwitchToVisible}
              </button>
            </a> &nbsp;
            {this.props.can_create_workspaces === 'True'
              ? <a href='/workspace/'>
                  <button className='btn' data-placement='left' title={transNewProjectDesc} data-toggle='tooltip'>
                    {transNewProject}
                  </button>
                </a> : null}
          </div>
        </div>
        <div className='row mt-medium'>
          <div className='col-md-12'>
            <div className='table'>
              <ReactTable
                defaultSorted={[{id: 'wk', desc: false}]}
                minRows={2}
                sortable
                showPagination={false}
                pageSize={this.state.workspaces ? this.state.workspaces.length : 10}
                data={this.state.workspaces.filter(wk => wk.visible === this.state.visible)}
                columns={tableColumns}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ProjectsBoard.propTypes = {
  can_create_workspaces: PropTypes.string.isRequired,
  workspaces: PropTypes.string.isRequired
}

export default ProjectsBoard
